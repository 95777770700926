// App.js

import React, { useState, useEffect } from 'react';
import {
  Routes,
  Route,
  Link,
  useParams,
  useSearchParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { ethers, formatEther, parseEther } from 'ethers';
import './App.css';
import NewspaperABI from './NewspaperABI.json';
import WhyJoin from './WhyJoin';
import CookieConsentBanner from './CookieConsent'; // Import the Cookie Consent Banner
import PrivacyPolicy from './PrivacyPolicy';

// Allowed image hosts
const ALLOWED_IMAGE_HOSTS = [
  'imgur.com',
  'flickr.com',
  'photobucket.com',
  '500px.com',
  'smugmug.com',
  'tinypic.com',
  'postimg.cc',
  'imageshack.us',
  'deviantart.com',
  'cloudinary.com',
];

const contractAddress = '0xbf48BC74e58639F33B76FBe8eE14DA23eF89F5F5';

let contract;
let contractReadOnly;
document.title = 'oscuro';

// Helper function to extract hashtags from content
function extractTagsFromContent(content) {
  const regex = /#(\w+)/g;
  const tags = [];
  let match;
  while ((match = regex.exec(content))) {
    tags.push(match[1]);
  }
  return tags;
}

function extractYouTubeVideoID(url) {
  let videoId = null;
  try {
    const urlObj = new URL(url);
    if (urlObj.hostname.includes('youtube.com')) {
      if (urlObj.searchParams.has('v')) {
        videoId = urlObj.searchParams.get('v');
      } else if (urlObj.pathname.includes('/embed/')) {
        videoId = urlObj.pathname.split('/embed/')[1];
      }
    } else if (urlObj.hostname === 'youtu.be') {
      videoId = urlObj.pathname.substring(1);
    }
    // Remove any additional query parameters
    if (videoId) {
      videoId = videoId.split(/[&?]/)[0];
    }
  } catch (error) {
    console.error('Invalid YouTube URL:', url);
  }
  return videoId;
}

// Function to validate graphic URLs
function validateGraphicUrls(content) {
  const regex = /<graphic>(.*?)<\/graphic>/g;
  let match;
  while ((match = regex.exec(content))) {
    const url = match[1].trim();
    try {
      const urlObj = new URL(url);
      const hostname = urlObj.hostname.replace('www.', '').toLowerCase();

      // Check if hostname exactly matches or is a subdomain of an allowed host
      const isAllowed = ALLOWED_IMAGE_HOSTS.some((allowedHost) => {
        return (
          hostname === allowedHost.toLowerCase() ||
          hostname.endsWith(`.${allowedHost.toLowerCase()}`)
        );
      });

      if (!isAllowed) {
        return false;
      }
    } catch (error) {
      // Invalid URL format
      return false;
    }
  }
  return true;
}

// Modify the parseContent function
function parseContent(content) {
  // Split content into parts, including <graphic> and <video> tags
  const parts = content.split(
    /(<graphic>.*?<\/graphic>|<video>.*?<\/video>)/gs
  );
  return parts.map((part, index) => {
    if (part.startsWith('<graphic>') && part.endsWith('</graphic>')) {
      // Existing code for handling <graphic> tags
      const url = part.slice(9, -10).trim(); // Remove <graphic> and </graphic>
      return (
        <img key={index} src={url} alt="Content" className="content-image" />
      );
    } else if (part.startsWith('<video>') && part.endsWith('</video>')) {
      // New code for handling <video> tags
      const url = part.slice(7, -8).trim(); // Remove <video> and </video>
      const videoId = extractYouTubeVideoID(url);
      if (videoId) {
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        return (
          <div key={index} className="video-container">
            <iframe
              src={embedUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        );
      } else {
        // If the video ID could not be extracted, render the URL as plain text
        return <span key={index}>{url}</span>;
      }
    } else {
      // Existing code for handling text outside of <graphic> and <video> tags
      return <span key={index}>{convertUrlsToLinks(part)}</span>;
    }
  });
}

// Helper function to convert URLs in text to hyperlinks
function convertUrlsToLinks(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);
  return parts.map((part, subIndex) => {
    if (urlRegex.test(part)) {
      return (
        <a
          key={subIndex}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
        >
          {part}
        </a>
      );
    } else {
      return <span key={subIndex}>{part}</span>;
    }
  });
}

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [comments, setComments] = useState({});
  const [showComments, setShowComments] = useState({});
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [postPseudonym, setPostPseudonym] = useState(''); // Added pseudonym state for news posts
  const [commentMessage, setCommentMessage] = useState('');
  const [commentPseudonym, setCommentPseudonym] = useState('');
  const [newsTips, setNewsTips] = useState({});
  const [commentTips, setCommentTips] = useState({});
  const [tags, setTags] = useState([]);
  const [postTip, setPostTip] = useState('0.001'); // Initialized with default value
  const [commentTip, setCommentTip] = useState('0.001');
  const [dateFilter, setDateFilter] = useState('all');
  const [isConnected, setIsConnected] = useState(false); // New state for wallet connection
  const [userAddress, setUserAddress] = useState(''); // New state for user's address

  const [searchParams, setSearchParams] = useSearchParams();
  const tagFilter = searchParams.get('tag');

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 15;
  const [totalPages, setTotalPages] = useState(1);
  const [isProviderInitialized, setIsProviderInitialized] = useState(false);
  const location = useLocation();

  // Combined useEffect to handle fetching news based on dependencies
  useEffect(() => {
    if (isProviderInitialized && location.pathname === '/') {
      fetchNews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProviderInitialized, location.pathname, currentPage, dateFilter, tagFilter]);

  useEffect(() => {
    // Detect if the device is mobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Initialize provider
    initializeProvider().then(() => {
      setIsProviderInitialized(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialize the Ethereum provider and contract
  async function initializeProvider() {
    if (window.ethereum) {
      try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const accounts = await provider.send('eth_requestAccounts', []); // Request account access
        const signer = await provider.getSigner();

        contract = new ethers.Contract(contractAddress, NewspaperABI, signer);
        contractReadOnly = new ethers.Contract(
          contractAddress,
          NewspaperABI,
          provider
        );
        setIsConnected(true);
        setUserAddress(accounts[0]); // Set the user's address
        return true;
      } catch (error) {
        console.error('Error initializing provider:', error);
        // Initialize read-only provider if user rejects connection
        initializeReadOnlyProvider();
        setIsConnected(false);
        return false;
      }
    } else {
      // Initialize a read-only provider
      initializeReadOnlyProvider();
      setIsConnected(false);
      return false;
    }
  }

  function initializeReadOnlyProvider() {
    // Specify the correct network (e.g., 'goerli', 'rinkeby', etc.)
    const network = 'http://127.0.0.1:8545'; // Replace with your network
    const provider = ethers.getDefaultProvider(network);
    contractReadOnly = new ethers.Contract(
      contractAddress,
      NewspaperABI,
      provider
    );
  }

  // Add a function to handle manual wallet connection
  const handleConnectWallet = async () => {
    const connected = await initializeProvider();
    if (!connected) {
      alert('Failed to connect to the Ethereum wallet.');
    }
  };

  // Fetch news posts
  const fetchNews = async () => {
    try {
      const newsArray = [];
      const tagCount = {};
      const newsIdRaw = await contractReadOnly.static_news_id();
      const newsId = Number(newsIdRaw);
      console.log('Total News ID:', newsId);

      if (newsId === 0) {
        console.log('No news posts available. Please create one.');
        setNewsList([]);
        setTags([]);
        setTotalPages(0);
        return;
      }

      // First, fetch minimal data (ID, timestamp, tags) for all posts to apply filters and calculate total pages
      const allPosts = [];
      for (let i = newsId; i >= 1; i--) {
        try {
          const newsItem = await contractReadOnly.news(i);
          const contentText = newsItem.content;
          const extractedTags = extractTagsFromContent(contentText);
          const timestamp = Number(newsItem.date.toString()) * 1000; // Convert seconds to milliseconds

          allPosts.push({
            id: i,
            tags: extractedTags,
            timestamp: timestamp,
          });

          extractedTags.forEach((tag) => {
            const lowerTag = tag.toLowerCase();
            tagCount[lowerTag] = (tagCount[lowerTag] || 0) + 1;
          });
        } catch (error) {
          console.error(`Error fetching news with ID ${i}:`, error);
        }
      }

      // Apply Filters
      const filteredPosts = allPosts.filter(
        (post) =>
          (!tagFilter ||
            post.tags
              .map((t) => t.toLowerCase())
              .includes(tagFilter.toLowerCase())) &&
          isWithinDateRange(post.timestamp)
      );

      // Calculate total pages based on filtered posts
      const totalFilteredPosts = filteredPosts.length;
      const calculatedTotalPages = Math.ceil(totalFilteredPosts / postsPerPage);
      setTotalPages(calculatedTotalPages);

      // Get posts for the current page
      const indexOfLastPost = currentPage * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      const currentPostIds = filteredPosts
        .sort((a, b) => b.timestamp - a.timestamp)
        .slice(indexOfFirstPost, indexOfLastPost)
        .map((post) => post.id);

      // Now fetch full data for the posts on the current page
      const currentPosts = [];
      for (const postId of currentPostIds) {
        try {
          const newsItem = await contractReadOnly.news(postId);
          const totalTips = newsItem.totalTips
            ? parseFloat(formatEther(newsItem.totalTips))
            : 0;

          const contentText = newsItem.content;
          const extractedTags = extractTagsFromContent(contentText);

          const timestamp = Number(newsItem.date.toString()) * 1000; // Convert seconds to milliseconds
          const formattedDate = new Date(timestamp).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          });

          currentPosts.push({
            id: postId,
            title: newsItem.title,
            content: contentText,
            totalTips: totalTips,
            tags: extractedTags,
            date: formattedDate,
            timestamp: timestamp,
            pseudonym: newsItem.pseudonim,
          });
        } catch (error) {
          console.error(`Error fetching news with ID ${postId}:`, error);
        }
      }

      setNewsList(currentPosts);

      const sortedTags = Object.entries(tagCount)
        .sort(([, countA], [, countB]) => countB - countA)
        .map(([tag]) => tag);

      setTags(sortedTags.slice(0, 10));
    } catch (error) {
      console.error('Error fetching news:', error);
    }
  };

  // Determine if a news item is within the selected date range
  const isWithinDateRange = (timestamp) => {
    if (dateFilter === 'all') return true;

    const now = Date.now();
    const sixHours = 6 * 60 * 60 * 1000;
    const oneDay = 24 * 60 * 60 * 1000;
    const thirtyOneDays = 31 * 24 * 60 * 60 * 1000;

    switch (dateFilter) {
      case '6h':
        return now - timestamp <= sixHours;
      case '1d':
        return now - timestamp <= oneDay;
      case '31d':
        return now - timestamp <= thirtyOneDays;
      default:
        return true;
    }
  };

  // Create a new news post with validation
  const createNews = async () => {
    if (!title.trim() || !content.trim() || !postPseudonym.trim()) {
      alert('Title, Content, and Pseudonym are required.');
      return;
    }

    // Validate graphic URLs
    if (!validateGraphicUrls(content)) {
      alert(
        `Only images from the following sites are allowed within <graphic> tags: ${ALLOWED_IMAGE_HOSTS.join(
          ', '
        )} and their subdomains. Please update your content accordingly.`
      );
      return;
    }

    if (!isConnected) {
      // Prompt the user to connect
      const connected = await initializeProvider();
      if (!connected) {
        alert('Please connect to an Ethereum wallet to create posts.');
        return;
      }
    }

    try {
      const tipAmount = postTip || '0.001';
      const tx = await contract.create_news(title, content, postPseudonym, {
        value: parseEther(tipAmount),
      });
      await tx.wait();
      alert('News post created successfully!');
      setTitle('');
      setContent('');
      setPostPseudonym('');
      setPostTip('0.001'); // Reset to default after posting

      // Reset to first page to show the newly added post
      if (currentPage !== 1) {
        setCurrentPage(1);
      } else {
        // If already on page 1, manually fetch news to include the new post
        fetchNews();
      }
    } catch (error) {
      console.error('Error creating news:', error);
      alert('Failed to create news. Please try again.');
    }
  };

  // Create a new comment on a post with validation
  const createComment = async (postId) => {
    if (!commentPseudonym.trim() || !commentMessage.trim()) {
      alert('Pseudonym and Message are required.');
      return;
    }

    // Validate graphic URLs
    if (!validateGraphicUrls(commentMessage)) {
      alert(
        `Only images from the following sites are allowed within <graphic> tags: ${ALLOWED_IMAGE_HOSTS.join(
          ', '
        )} and their subdomains. Please update your comment accordingly.`
      );
      return;
    }

    if (!isConnected) {
      // Prompt the user to connect
      const connected = await initializeProvider();
      if (!connected) {
        alert('Please connect to an Ethereum wallet to add comments.');
        return;
      }
    }

    try {
      const tipAmount = commentTip || '0.001';
      const tx = await contract.create_comment(
        commentMessage,
        commentPseudonym,
        postId,
        {
          value: parseEther(tipAmount),
        }
      );
      await tx.wait();
      alert('Comment created successfully!');
      setCommentMessage('');
      setCommentPseudonym('');
      setCommentTip('0.001');
      fetchComments(postId);
    } catch (error) {
      console.error('Error creating comment:', error);
      alert('Failed to create comment. Please try again.');
    }
  };

  // Fetch comments for a specific post
  const fetchComments = async (postId) => {
    try {
      const commentsArray = [];
      const commentIdRaw = await contractReadOnly.static_comment_id();
      const commentId = Number(commentIdRaw);
      console.log('Total Comment ID:', commentId);

      if (commentId === 0) {
        console.log('No comments available.');
        setComments((prev) => ({ ...prev, [postId]: [] }));
        return;
      }

      for (let i = commentId; i >= 1; i--) {
        try {
          const comment = await contractReadOnly.comments(i);
          if (comment.id_of_news.toString() === postId.toString()) {
            const totalTipsValue =
              comment.totalTips && comment.totalTips !== '0'
                ? parseFloat(formatEther(comment.totalTips))
                : 0;

            const timestamp = Number(comment.date.toString()) * 1000; // Convert seconds to milliseconds
            const formattedDate = new Date(timestamp).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            });

            commentsArray.push({
              comment_id: comment.comment_id.toString(),
              pseudonym: comment.pseudonim,
              message: comment.message,
              totalTips: totalTipsValue,
              date: formattedDate,
              timestamp: timestamp,
            });
          }
        } catch (error) {
          console.error(`Error fetching comment with ID ${i}:`, error);
        }
      }

      setComments((prev) => ({ ...prev, [postId]: commentsArray }));
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  // Reward the creator of a news post
  const rewardNewsCreator = async (postId) => {
    const tipAmount = newsTips[postId] || '0.02';
    if (tipAmount === '') {
      alert('Please enter a tip amount.');
      return;
    }

    if (!isConnected) {
      // Prompt the user to connect
      const connected = await initializeProvider();
      if (!connected) {
        alert('Please connect to an Ethereum wallet to reward the creator.');
        return;
      }
    }

    try {
      const tx = await contract.reward_news_creator(postId, {
        value: parseEther(tipAmount),
      });
      await tx.wait();
      alert('Reward sent successfully!');
      setNewsTips((prev) => ({ ...prev, [postId]: '' }));
      fetchNews();
    } catch (error) {
      console.error('Error rewarding news creator:', error);
      alert('Failed to reward creator. Please try again.');
    }
  };

  // Reward the creator of a comment
  const rewardCommentCreator = async (commentId, postId) => {
    const tipAmount = commentTips[commentId] || '0.001';
    if (tipAmount === '') {
      alert('Please enter a tip amount.');
      return;
    }

    if (!isConnected) {
      // Prompt the user to connect
      const connected = await initializeProvider();
      if (!connected) {
        alert('Please connect to an Ethereum wallet to reward the commenter.');
        return;
      }
    }

    try {
      const tx = await contract.reward_comment_creator(commentId, {
        value: parseEther(tipAmount),
      });
      await tx.wait();
      alert('Reward sent successfully!');
      setCommentTips((prev) => ({ ...prev, [commentId]: '' }));
      fetchComments(postId);
    } catch (error) {
      console.error('Error rewarding comment creator:', error);
      alert('Failed to reward comment creator. Please try again.');
    }
  };

  // Filter posts by tag
  const filterByTag = (tag) => {
    setSearchParams({ tag });
    setCurrentPage(1); // Reset to first page when filter changes
  };

  // Clear tag filter
  const clearTagFilter = () => {
    setSearchParams({});
    setCurrentPage(1); // Reset to first page when filter changes
  };

  // Handle date filter changes
  const handleDateFilterChange = (filter) => {
    setDateFilter(filter);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  // Toggle comments visibility for a post
  const toggleComments = (postId) => {
    if (showComments[postId]) {
      setShowComments((prev) => ({ ...prev, [postId]: false }));
    } else {
      fetchComments(postId);
      setShowComments((prev) => ({ ...prev, [postId]: true }));
    }
  };

  // Handle Next Page
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  // Handle Previous Page
  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Render Create News section
  const createNewsSection = (
    <section className="create-news">
      <h2>Create News</h2>
      <input
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <textarea
        placeholder={`Use #hashtags, embed image URLs inside <graphic> tags, YouTube videos in <video> tags.`}
        value={content}
        onChange={(e) => setContent(e.target.value)}
        rows="4"
      />
      <input
        placeholder="Pseudonym"
        value={postPseudonym}
        onChange={(e) => setPostPseudonym(e.target.value)}
      />
      {/* Wrap the input and button inside a div with class "create-news-actions" */}
      <div className="create-news-actions">
        <input
          type="number"
          min="0"
          step="0.001"
          placeholder="Tip amount (ETH)"
          value={postTip}
          onChange={(e) => setPostTip(e.target.value)}
        />
        <button onClick={createNews}>Post News</button>
      </div>
    </section>
  );

  return (
    <div className="App">
      {/* Static Header */}
      <header>
        <h2>OSCURO.IO</h2>
        <div className="motto">blockchain based microblogging</div>
        <CookieConsentBanner />
        {/* Connect Wallet Button */}
        <div className="wallet-connection">
          {isConnected ? (
            <span className="connected-status">
              Connected: {userAddress.slice(0, 6)}...{userAddress.slice(-4)}
            </span>
          ) : (
            <a href="#connect-wallet" onClick={handleConnectWallet}>
              Connect a wallet
            </a>
          )}
        </div>
      </header>

      {/* Conditional Rendering Based on Provider Initialization */}
      {isProviderInitialized ? (
        <Routes>
          <Route
            path="/"
            element={
              <div className="main-content">
                {/* Render Create News section at the top if mobile */}
                {isMobile && createNewsSection}
                <div className="sidebar">
                  {/* Popular Tags */}
                  <section className="tag-cloud">
                    <h2>Popular Tags</h2>
                    <div className="hashtags">
                      {tags.length > 0 ? (
                        tags.map((tag) => (
                          <span
                            key={tag}
                            className="hashtag"
                            onClick={() => filterByTag(tag)}
                          >
                            #{tag}
                          </span>
                        ))
                      ) : (
                        <p>No tags available.</p>
                      )}
                    </div>
                    {tagFilter && (
                      <button
                        className="clear-filter-btn"
                        onClick={clearTagFilter}
                      >
                        Clear Filter
                      </button>
                    )}
                  </section>

                  {/* Date Filter Section */}
                  <section className="date-filter">
                    <h2>Filter by Date</h2>
                    <div className="date-buttons">
                      <button
                        className={dateFilter === 'all' ? 'active' : ''}
                        onClick={() => handleDateFilterChange('all')}
                      >
                        All
                      </button>
                      <button
                        className={dateFilter === '6h' ? 'active' : ''}
                        onClick={() => handleDateFilterChange('6h')}
                      >
                        Last 6 Hours
                      </button>
                      <button
                        className={dateFilter === '1d' ? 'active' : ''}
                        onClick={() => handleDateFilterChange('1d')}
                      >
                        Last 1 Day
                      </button>
                      <button
                        className={dateFilter === '31d' ? 'active' : ''}
                        onClick={() => handleDateFilterChange('31d')}
                      >
                        Last 31 Days
                      </button>
                    </div>
                  </section>

                  {/* Render WhyJoin only if not mobile */}
                  {!isMobile && <WhyJoin />}
                </div>

                <div className="content-wrapper">
                  {/* Render Create News section here if not mobile */}
                  {!isMobile && createNewsSection}

                  {/* News List */}
                  <div className="news-list">
                    {newsList.length > 0 ? (
                      newsList.map((news) => (
                        <div key={news.id} className="news-item">
                          <h2>
                            <Link
                              to={`/post/${news.id}`}
                              className="post-title-link"
                            >
                              {news.title}
                            </Link>
                          </h2>
                          <div className="post-meta">
                            <small className="post-date">{news.date}</small>
                            <small className="total-tips">
                              Total Tips: {news.totalTips.toFixed(4)} ETH
                            </small>
                          </div>
                          <div className="hashtags">
                            {news.tags.map((tag) => (
                              <span
                                key={tag}
                                className="hashtag"
                                onClick={() => filterByTag(tag)}
                              >
                                #{tag}
                              </span>
                            ))}
                          </div>
                          <div className="content-display">
                            {parseContent(news.content)}
                          </div>
                          <p>
                            <strong>By: {news.pseudonym}</strong>
                          </p>
                          <div className="news-actions">
                            <input
                              type="number"
                              min="0"
                              step="0.001"
                              placeholder="Reward news creator (ETH)"
                              value={newsTips[news.id] || ''}
                              onChange={(e) =>
                                setNewsTips({
                                  ...newsTips,
                                  [news.id]: e.target.value,
                                })
                              }
                            />
                            <button onClick={() => rewardNewsCreator(news.id)}>
                              Reward Creator
                            </button>
                            <button onClick={() => toggleComments(news.id)}>
                              {showComments[news.id]
                                ? 'Hide Comments'
                                : 'View Comments'}
                            </button>
                          </div>

                          {showComments[news.id] && (
                            <>
                              <hr className="section-divider" />
                              {/* Add Comment Section */}
                              <section>
                                <h3>Add a Comment</h3>
                                <input
                                  placeholder="Pseudonym"
                                  value={commentPseudonym}
                                  onChange={(e) =>
                                    setCommentPseudonym(e.target.value)
                                  }
                                />
                                <textarea
                                  placeholder={`Message (use image URLs inside <graphic> tags)`}
                                  value={commentMessage}
                                  onChange={(e) =>
                                    setCommentMessage(e.target.value)
                                  }
                                />
                                <input
                                  type="number"
                                  min="0"
                                  step="0.001"
                                  placeholder="Tip amount (ETH)"
                                  value={commentTip}
                                  onChange={(e) =>
                                    setCommentTip(e.target.value)
                                  }
                                />
                                <button onClick={() => createComment(news.id)}>
                                  Comment
                                </button>
                              </section>

                              {/* Comments Section */}
                              <section>
                                <h3>Comments</h3>
                                {comments[news.id]?.length > 0 ? (
                                  [...comments[news.id]]
                                    .sort((a, b) => b.totalTips - a.totalTips)
                                    .map((comment) => (
                                      <div
                                        key={comment.comment_id}
                                        className="comment"
                                      >
                                        <p>
                                          <strong>{comment.pseudonym}:</strong>
                                        </p>
                                        <div className="comment-meta">
                                          <small className="comment-date">
                                            {comment.date}
                                          </small>
                                          <small className="total-tips">
                                            Total Tips:{' '}
                                            {comment.totalTips.toFixed(4)} ETH
                                          </small>
                                        </div>
                                        <div className="content-display">
                                          {parseContent(comment.message)}
                                        </div>
                                        <div className="comment-reward">
                                          <input
                                            type="number"
                                            min="0"
                                            step="0.001"
                                            placeholder="Tip amount (ETH)"
                                            value={
                                              commentTips[comment.comment_id] || ''
                                            }
                                            onChange={(e) =>
                                              setCommentTips({
                                                ...commentTips,
                                                [comment.comment_id]:
                                                  e.target.value,
                                              })
                                            }
                                          />
                                          <button
                                            onClick={() =>
                                              rewardCommentCreator(
                                                comment.comment_id,
                                                news.id
                                              )
                                            }
                                          >
                                            Tip Comment Creator
                                          </button>
                                        </div>
                                      </div>
                                    ))
                                ) : (
                                  <p>
                                    No comments yet. Be the first to comment!
                                  </p>
                                )}
                              </section>
                            </>
                          )}
                        </div>
                      ))
                    ) : (
                      <p>No news posts available. Please create one.</p>
                    )}
                  </div>
                  {/* Pagination Controls */}
                  {totalPages > 1 && (
                    <div className="pagination">
                      <button
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        className="pagination-btn"
                      >
                        Previous
                      </button>
                      {[...Array(totalPages)].map((_, index) => {
                        const pageNumber = index + 1;
                        return (
                          <button
                            key={pageNumber}
                            onClick={() => paginate(pageNumber)}
                            className={`pagination-btn ${
                              currentPage === pageNumber ? 'active' : ''
                            }`}
                          >
                            {pageNumber}
                          </button>
                        );
                      })}
                      <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="pagination-btn"
                      >
                        Next
                      </button>
                    </div>
                  )}

                  {/* Render WhyJoin at the end if mobile */}
                  {isMobile && <WhyJoin />}
                </div>
              </div>
            }
          />
          <Route
            path="/post/:id"
            element={
              <div>
                <nav>
                  <Link to="/">Main page</Link>
                </nav>
                <Post
                  isConnected={isConnected}
                  initializeProvider={initializeProvider}
                  userAddress={userAddress}
                  handleConnectWallet={handleConnectWallet}
                />
              </div>
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      ) : (
        // Loading Indicator While Initializing
        <div className="loading-container">
          <p>Loading application, please wait...</p>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
}

// Post Component for /post/:id route
function Post({ isConnected, initializeProvider, userAddress, handleConnectWallet }) {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [commentMessage, setCommentMessage] = useState('');
  const [commentPseudonym, setCommentPseudonym] = useState('');
  const [commentTips, setCommentTips] = useState({});
  const [commentTip, setCommentTip] = useState('0.001');
  const [newsTip, setNewsTip] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    fetchPost();
    fetchComments(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Fetch a single post
  const fetchPost = async () => {
    try {
      const newsItem = await contractReadOnly.news(id);
      console.log(`Fetched Post ID: ${id}`, newsItem);
      const totalTips = newsItem.totalTips
        ? parseFloat(formatEther(newsItem.totalTips))
        : 0;
      const contentText = newsItem.content;
      const tags = extractTagsFromContent(contentText);

      const timestamp = Number(newsItem.date.toString()) * 1000; // Convert seconds to milliseconds
      const formattedDate = new Date(timestamp).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });

      setPost({
        id: id,
        title: newsItem.title,
        content: contentText,
        totalTips: totalTips,
        tags: tags,
        date: formattedDate,
        timestamp: timestamp,
        pseudonym: newsItem.pseudonim, // Added pseudonym here
      });
      setError('');
    } catch (error) {
      console.error('Error fetching post:', error);
      setError('Failed to fetch the post. It might not exist.');
      setPost(null);
    }
  };

  // Fetch comments for the post
  const fetchComments = async (postId) => {
    try {
      const commentsArray = [];
      const commentIdRaw = await contractReadOnly.static_comment_id();
      const commentId = Number(commentIdRaw);
      console.log('Total Comment ID:', commentId);

      if (commentId === 0) {
        console.log('No comments available.');
        setComments([]);
        return;
      }

      for (let i = commentId; i >= 1; i--) {
        try {
          const comment = await contractReadOnly.comments(i);
          if (comment.id_of_news.toString() === postId.toString()) {
            const totalTipsValue =
              comment.totalTips && comment.totalTips !== '0'
                ? parseFloat(formatEther(comment.totalTips))
                : 0;

            const timestamp = Number(comment.date.toString()) * 1000; // Convert seconds to milliseconds
            const formattedDate = new Date(timestamp).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            });

            commentsArray.push({
              comment_id: comment.comment_id.toString(),
              pseudonym: comment.pseudonim,
              message: comment.message,
              totalTips: totalTipsValue,
              date: formattedDate,
              timestamp: timestamp,
            });
          }
        } catch (error) {
          console.error(`Error fetching comment with ID ${i}:`, error);
        }
      }

      setComments(commentsArray);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  // Function to validate graphic URLs
  const validateGraphicUrls = (content) => {
    const regex = /<graphic>(.*?)<\/graphic>/g;
    let match;
    while ((match = regex.exec(content))) {
      const url = match[1].trim();
      try {
        const urlObj = new URL(url);
        const hostname = urlObj.hostname.replace('www.', '').toLowerCase();
        const isAllowed = ALLOWED_IMAGE_HOSTS.some((allowedHost) => {
          return (
            hostname === allowedHost.toLowerCase() ||
            hostname.endsWith(`.${allowedHost.toLowerCase()}`)
          );
        });
        if (!isAllowed) {
          return false;
        }
      } catch (error) {
        // Invalid URL format
        return false;
      }
    }
    return true;
  };

  // Create a new comment on the post with validation
  const createComment = async () => {
    if (!commentPseudonym.trim() || !commentMessage.trim()) {
      alert('Pseudonym and Message are required.');
      return;
    }

    // Validate graphic URLs
    if (!validateGraphicUrls(commentMessage)) {
      alert(
        `Only images from the following sites are allowed within <graphic> tags: ${ALLOWED_IMAGE_HOSTS.join(
          ', '
        )} and their subdomains. Please update your comment accordingly.`
      );
      return;
    }

    if (!isConnected) {
      // Prompt the user to connect
      const connected = await initializeProvider();
      if (!connected) {
        alert('Please connect to an Ethereum wallet to add comments.');
        return;
      }
    }

    try {
      const tipAmount = commentTip || '0.001';
      const tx = await contract.create_comment(
        commentMessage,
        commentPseudonym,
        id,
        {
          value: parseEther(tipAmount),
        }
      );
      await tx.wait();
      alert('Comment created successfully!');
      setCommentMessage('');
      setCommentPseudonym('');
      setCommentTip('0.001');
      fetchComments(id);
    } catch (error) {
      console.error('Error creating comment:', error);
      alert('Failed to create comment. Please try again.');
    }
  };

  // Reward the creator of the news post
  const rewardNewsCreator = async () => {
    const tipAmount = newsTip || '0.02';
    if (tipAmount === '') {
      alert('Please enter a tip amount.');
      return;
    }

    if (!isConnected) {
      // Prompt the user to connect
      const connected = await initializeProvider();
      if (!connected) {
        alert('Please connect to an Ethereum wallet to reward the creator.');
        return;
      }
    }

    try {
      const tx = await contract.reward_news_creator(id, {
        value: parseEther(tipAmount),
      });
      await tx.wait();
      alert('Reward sent successfully!');
      setNewsTip('');
      fetchPost();
    } catch (error) {
      console.error('Error rewarding news creator:', error);
      alert('Failed to reward creator. Please try again.');
    }
  };

  // Reward the creator of a comment
  const rewardCommentCreator = async (commentId, postId) => {
    const tipAmount = commentTips[commentId] || '0.001';
    if (tipAmount === '') {
      alert('Please enter a tip amount.');
      return;
    }

    if (!isConnected) {
      // Prompt the user to connect
      const connected = await initializeProvider();
      if (!connected) {
        alert('Please connect to an Ethereum wallet to reward the commenter.');
        return;
      }
    }

    try {
      const tx = await contract.reward_comment_creator(commentId, {
        value: parseEther(tipAmount),
      });
      await tx.wait();
      alert('Reward sent successfully!');
      setCommentTips((prev) => ({ ...prev, [commentId]: '' }));
      fetchComments(postId);
    } catch (error) {
      console.error('Error rewarding comment creator:', error);
      alert('Failed to reward comment creator. Please try again.');
    }
  };

  // Navigate to filtered posts by tag
  const handleTagClick = (tag) => {
    navigate(`/?tag=${tag}`);
  };

  if (error) {
    return (
      <div className="App">
        <div className="error-message">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="App">
        <div className="loading-message">
          <p>Loading post...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="post-container">
        <div className="news-item">
          <h2 className="post-title">{post.title}</h2>
          <div className="post-meta">
            <small className="post-date">{post.date}</small>
            <small className="total-tips">
              Total Tips: {post.totalTips.toFixed(4)} ETH
            </small>
          </div>
          <p>
            <strong>By: {post.pseudonym}</strong>
          </p>
          <div className="hashtags">
            {post.tags.map((tag) => (
              <span
                key={tag}
                className="hashtag"
                onClick={() => handleTagClick(tag)}
              >
                #{tag}
              </span>
            ))}
          </div>
          <div className="content-display">{parseContent(post.content)}</div>
          <div className="news-actions">
            <input
              type="number"
              min="0"
              step="0.001"
              placeholder="Tip amount (ETH)"
              value={newsTip}
              onChange={(e) => setNewsTip(e.target.value)}
            />
            <button onClick={rewardNewsCreator}>Reward Creator</button>
          </div>

          <hr className="section-divider" />

          {/* Add Comment Section */}
          <section>
            <h3>Add a Comment</h3>
            <input
              placeholder="Pseudonym"
              value={commentPseudonym}
              onChange={(e) => setCommentPseudonym(e.target.value)}
            />
            <textarea
              placeholder={`Message (use image URLs inside <graphic> tags)`}
              value={commentMessage}
              onChange={(e) => setCommentMessage(e.target.value)}
            />
            <input
              type="number"
              min="0"
              step="0.001"
              placeholder="Tip amount (ETH)"
              value={commentTip}
              onChange={(e) => setCommentTip(e.target.value)}
            />
            <button onClick={createComment}>Comment</button>
          </section>

          {/* Comments Section */}
          <section>
            <h3>Comments</h3>
            {comments.length > 0 ? (
              comments.map((comment) => (
                <div key={comment.comment_id} className="comment">
                  <p>
                    <strong>{comment.pseudonym}:</strong>
                  </p>
                  <div className="comment-meta">
                    <small className="comment-date">{comment.date}</small>
                    <small className="total-tips">
                      Total Tips: {comment.totalTips.toFixed(4)} ETH
                    </small>
                  </div>
                  <div className="content-display">
                    {parseContent(comment.message)}
                  </div>
                  <div className="comment-reward">
                    <input
                      type="number"
                      min="0"
                      step="0.001"
                      placeholder="Tip amount (ETH)"
                      value={commentTips[comment.comment_id] || ''}
                      onChange={(e) =>
                        setCommentTips({
                          ...commentTips,
                          [comment.comment_id]: e.target.value,
                        })
                      }
                    />
                    <button
                      onClick={() =>
                        rewardCommentCreator(comment.comment_id, post.id)
                      }
                    >
                      Tip Comment Creator
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No comments yet. Be the first to comment!</p>
            )}
          </section>
        </div>
      </div>
    </div>
  );
}

// Define the Footer component
function Footer() {
  return (
    <footer>
      <small>
        <Link to="/privacy-policy"> Privacy Policy</Link>
        <br />
        &copy; 2024 oscuro. All rights reserved
      </small>
    </footer>
  );
}

export default App;
