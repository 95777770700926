// PrivacyPolicy.js

import React from 'react';
import './PrivacyPolicy.css'; 
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
                        <Link to="/">Main page</Link>

      <h1>Privacy Policy for oscuro.io</h1>
      <p><strong>Effective Date:</strong> November 25, 2024</p>

      <h2>Introduction</h2>
      <p>
        Welcome to oscuro.io ("we," "us," or "our"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.
      </p>

      <h2>1. Information We Collect</h2>
      <h3>Personal Data</h3>
      <p>
        When you interact with our smart contracts on the blockchain, your public wallet address may be recorded. We do not collect any personal identifiers like your name, email, or physical address.
      </p>

      <h3>Usage Data</h3>
      <p>
        We may collect information on how the service is accessed and used, such as your interactions with our platform.
      </p>

      <h3>Cookies and Tracking Technologies</h3>
      <p>
        We use cookies and similar tracking technologies to enhance your experience.
      </p>

      <h2>2. How We Use Your Information</h2>
      <ul>
        <li>
          <strong>To Provide and Maintain Our Service:</strong> To facilitate transactions and interactions on our platform.
        </li>
        <li>
          <strong>To Improve Our Platform:</strong> To understand how users interact with our services to enhance user experience.
        </li>
        <li>
          <strong>For Compliance:</strong> To comply with legal obligations and resolve disputes.
        </li>
      </ul>

      <h2>3. Cookies and Tracking Technologies</h2>
      <p>We use cookies to:</p>
      <ul>
        <li>Recognize you when you return to our site.</li>
        <li>Understand and save user preferences for future visits.</li>
        <li>Compile aggregate data about site traffic and site interactions.</li>
      </ul>

      <h2>4. Disclosure of Your Information</h2>
      <h3>Third-Party Service Providers</h3>
      <p>
        We do not share your personal data with third parties, except for blockchain interactions which are public by nature.
      </p>

      <h3>Legal Obligations</h3>
      <p>
        We may disclose your information to comply with legal obligations or protect our rights.
      </p>

      <h2>5. Security of Your Information</h2>
      <p>
        We prioritize the security of your data but remember that no method of transmission over the internet is 100% secure.
      </p>

      <h2>6. Children's Privacy</h2>
      <p>
        Our service is not intended for individuals under the age of 18.
      </p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by updating the "Effective Date" at the top of this Privacy Policy.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:service@oscuro.io">service@oscuro.io</a>.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
