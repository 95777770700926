// WhyJoin.js
import React from 'react';

function WhyJoin() {
  const points = [
    {
      title: 'Decentralized News Platform',
      description: 'Experience a censorship-resistant platform.',
    },
    {
      title: 'Earn ETH for Quality Content',
      description: 'Get rewarded for your contributions. 99% - you , 1 % - oscuro',
    },
    {
      title: 'Support Independent Journalism',
      description: 'Help promote unbiased news.',
    },
    {
      title: 'Engage with a Vibrant Community',
      description: 'Connect with like-minded individuals.',
    },
    {
        title: 'Pay only network fees',
        description: 'When adding the post or a comment you can support oscuro however its optional!'
    }
  ];

  return (
    <section className="why-join">
      <h2>Why Join oscuro?</h2>
      <div className="why-join-table">
        {points.map((point) => (
          <div key={point.title} className="why-join-row">
            <div className="why-join-title">{point.title}</div>
            <div className="why-join-description">{point.description}</div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default WhyJoin;
