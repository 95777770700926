// CookieConsent.js

import React from 'react';
import CookieConsent from 'react-cookie-consent';

function CookieConsentBanner() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="oscuroCookieConsent"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
      enableDeclineButton
    >
      We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.{" "}
      <a href="/privacy-policy" style={{ color: "#FFD700" }}>
        Learn more
      </a>
    </CookieConsent>
  );
}

export default CookieConsentBanner;